<template>
  <!-- <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    :right="$vuetify.rtl"
    @transitionend="collapseSubItems"
    id="default-drawer"
    mini-variant-width="80"
    width="260"
    app
  > -->
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    :right="$vuetify.rtl"
    @transitionend="collapseSubItems"
    id="default-drawer"
    mini-variant-width="80"
    width="260"
    fixed
    permanent
  >
    <template v-if="drawerImage" #img="props">
      <v-img gradient="#0e778c,#016A83" v-bind="props" />
    </template>

    <div class="px-2 child">
      <default-drawer-header />

      <!-- <v-divider class="mx-3 mb-2" /> -->

      <default-list :items="items" />
    </div>

    <template #append>
      <default-drawer-footer />
    </template>

    <!-- <div class="pt-12"></div> -->
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { get, sync } from "vuex-pathify";

export default {
  name: "DefaultDrawer",

  components: {
    DefaultDrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        "./DrawerHeader"
      ),
    DefaultList: () =>
      import(
        /* webpackChunkName: "default-list" */
        "./List"
      ),
    DefaultDrawerFooter: () => import("./DrawerFooter"),
  },

  computed: {
    // ...get("user", ["dark", "gradient", "image"]),
    ...get("app", ["items", "version"]),
    ...sync("app", ["drawer", "drawerImage", "mini"]),
  },
  data() {
    return {};
  },

  methods: {
    collapseSubItems() {
      if (this.mini) {
        this.items.forEach((el) => {
          if (el.items) {
            el.items.active = false;
          }
        });
      }
    },
  },
};
</script>

<style lang="sass">
#default-drawer
  // overflow: hidden
  z-index: 99
  top:48px !important
  height: calc(100% - 48px) !important
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
.child
  // width: 100%
  height: 100%
  // overflow-y: scroll
  overflow: auto
  padding-right: 17px
  box-sizing: content-box

  .logo_sm
    position: absolute
    left: 50%
    transform: translateX(-50%)
@media (max-width:1440px)
  .child
    width: inherit
    box-sizing: initial
</style>
